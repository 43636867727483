import queryString from 'query-string'

/**
 * @description represents operations on query parameters
 */
const QueryHelpers = {
  getParams(searchParams) {
    const search = searchParams || window.location.search
    return queryString.parse(search)
  },

  getParam(key, searchParams) {
    const search = searchParams || window.location.search
    return this.getParams(search)[key]
  },

  appendURLParams(url, searchParams, replace = false) {
    if (!searchParams) return url

    const isRelative = url.startsWith('/')
    const tempBase = 'http://example.com'

    const uri = new URL(url, isRelative ? tempBase : undefined)
    const params = new URLSearchParams(uri.search)

    Object.keys(searchParams).forEach(key => {
      if (!searchParams[key]) return
      if (params.get(key) && replace) params.delete(key) // remove conflicted key
      params.append(key, searchParams[key])
    })

    uri.search = params.toString()

    return isRelative ? uri.pathname + uri.search : uri.toString()
  },

  mergeParams(appendParams = {}, searchParams) {
    const search = searchParams || window.location.search
    return new URLSearchParams({
      ...this.getParams(search),
      ...appendParams
    }).toString()
  },

  getNavActiveness(nav) {
    return window.location.search.includes(`${QueryHelpers.keys.nav}=${nav}`)
  },

  replaceWindowParams(replaceParams = {}) {
    const newParams = QueryHelpers.mergeParams(replaceParams)
    window.history.replaceState(
      null,
      null,
      `${window.location.pathname}?${newParams}`
    )
  },

  keys: {
    nav: 'navigation', // used to highlight active navigation item
    redirectUrl: 'redirect_uri', // undefined considered as '/'
    successScreen: 'success_screen' // undefined considered as true
  }
}

export default QueryHelpers
